import { useMediaQuery } from "@mui/system";
import SurgirAccordion from "components/ui/SurgirAccordion/SurgirAccordion";
import SurgirButton from "components/ui/SurgirButton/SurgirButton";
import { SurgirModalDownload } from "components/ui/SurgirModalDownload/SurgirModalDownload";
import SurgirTabs from "components/ui/SurgirTabs/SurgirTabs";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import ConstruyendoSTablet from './../../assets/images/Banner_CS_Informativo Tablet_@2x.jpg';
import ConstruyendoS from './../../assets/images/Banner_CS_Informativo desktop_@2x.jpg';
import Uno from './../../assets/images/C1.svg';
import Dos from './../../assets/images/C2.svg';
import Tres from './../../assets/images/C3.svg';
import Construyendo from './../../assets/images/Construyendoprospera.png';
import DownloadIcon from './../../assets/images/Icons_descargar.svg';
import XLS from "./../../assets/images/XLS.svg";
import Chicas from './../../assets/images/chicassurgir.png';
import Chico from './../../assets/images/chicoprospera.png';
import ConstruyendoSMobile from './../../assets/images/construyendo sueños mobile.jpg';
import Mujeres from './../../assets/images/créditomujeres.webp';
import PDF from "./../../assets/images/pdf.svg";
import HandMoney from './../../assets/images/pictogramas.svg';
import Porki from './../../assets/images/porki.svg';
import Clock from './../../assets/images/wall-clock.svg';
import { frequentQuestions } from "./CreditProsperous.fixture";
import "./CreditProsperous.scss";
import { createLink } from "utils/urls";

const ItemCardCP = ({ id, position, text, ref, title, image, width }: any) => {
  return (
    <div
      id={id}
      className="cardsprospera"
      ref={ref}
      style={{
        width: `${width}%`,
        margin: "0 auto",
        marginBottom: 10,
        paddingRight: 30,
        paddingLeft: 30,
        height: "100%",

      }}

    >
      <div style={{ textAlign: "center" }}>
        <span className="position-item-card">
          {position}
          <span>{image}</span>
        </span>
        <span className="title-item-card">{title}</span>
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: 10,
          fontSize: 16,
          fontFamily: "Str-Microtext-Regular",
        }}
      >
        {text}
      </div>
    </div>
  );
};

const ItemTab2 = ({ list, last }: any) => {
  return (
    <ul
      style={{
        borderBottomStyle: "solid",
        borderBottomColor: "#cccccc",
        paddingTop: 10,
        paddingBottom: 2,
        paddingLeft: 20,
        borderBottomWidth: last ? 0 : 2,
      }}
    >
      {list.map((item: any) => {
        return (
          <li
            key={item}
            className={"list-tab-item"}
            style={{ listStyleType: "initial", listStylePosition: "outside" }}
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
};

function useWindowSize() {
  const [size, setSize] = React.useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const CreditProsperous: React.FC = () => {

  const construyendoRef = useRef<HTMLDivElement>(null);
  
  const TABS_CREDIT_PROSPEROUS = [
    {
      title: 'Características',
      eventKey: 'caracteristicas',
      body: (
        <div>
          <Row>
                  <Col md={4}>
                    <p className="Columna">Monto</p>
                  </Col>
                  <Col md={8}>
                    <ItemTab2
                      key={0}
                      list={["Desde S/ 1,000.00 hasta S/ 100,000.00"]}
                      last={true}
                    />

                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <p className="Columna">Pagos de cuota</p>
                  </Col>
                  <Col md={8}>
                    <ItemTab2
                      key={1}
                      list={[
                        "Paga una sola cuota fija al mes",
                      ]}
                      last={true}
                    />
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <p className="Columna">Plazo de crédito</p>
                  </Col>
                  <Col md={8}>
                    <ItemTab2
                      key={3}
                      list={[
                        "Capital de trabajo: desde 3 hasta 18 meses",
                        "Activo fijo: desde 3 hasta 36 meses."
                      ]}
                      last={true}
                    />

                    <hr />
                  </Col>
                </Row>
        </div>
      )
    },
    {
      title: 'Requisitos',
      eventKey: 'requisitos',
      body: (
        <div>
          <Row>
            <Col md={4}>
              <p className="Columna">Condición</p>
            </Col>
            <Col md={8}>
              <ItemTab2
                key={0}
                list={[
                  "Tener un negocio propio, como mínimo 6 meses de funcionamiento.        ",
                ]}
                last={true}
              />

              <hr />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <p className="Columna">Documentación</p>
            </Col>
            <Col md={8}>

              <ItemTab2 key={1} list={[

                ' Documento oficial de identidad del titular.                      ',
                'Recibo de servicios (luz, agua u otro) con una antigüedad máxima de 3 meses.                      ',

              ]}
                last={true}
              />
            </Col>
          </Row>
        </div>
      )
    },
    {
      title: 'Tasas y Tarifas',
      eventKey: 'tasasytarifas',
      body: (
        <Row style={{ padding: 40 }}>
          <Col md={3}>
            <p className="Columna">Tasas</p>
          </Col>
          <Col md={9}>
            <Card body>
              {" "}
              <Row>
                <Col md={9}>Tarifario de Tasas Activas</Col>
                <Col md={3}>
                  <a
                    className="Descarga"
                    style={{ textDecoration: "none", color: "#767676" }}
                    rel="noopener noreferrer"
                    download="Tarifario_Tasas_Activas"
                    onClick={() => createLink(process.env.REACT_APP_TARIFARIOS_ACTIVAS ?? "", "_blank")} 
                  >
                    {" "}
                    <img alt='' src={DownloadIcon} /> Descargar{" "}
                  </a>
                </Col>
              </Row>
            </Card>
            <br />
            <Card body>
              {" "}
              <Row>
                <Col md={9}>Tarifario de Comisiones y Seguros</Col>
                <Col md={3}>
                  <a
                    className="Descarga"
                    style={{ textDecoration: "none", color: "#767676" }}
                    rel="noopener noreferrer"
                    download="Tarifario_comisión_y_seguro"
                    onClick={() => createLink(process.env.REACT_APP_TARIFARIO_COMISION_Y_SEGURO ?? "", "_blank")} 
                  >
                    <img alt='' src={DownloadIcon} /> Descargar
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )
    },
    {
      title: 'Documentación',
      eventKey: 'documentación',
      body: (
        <Row style={{ padding: 40 }}>
          <Col md={3} style={{ marginRight: 20 }} >
            <p className="Columna">Documentos descargables</p>
          </Col>
          <Col md={9}>
            <Card body>
              {" "}
              <Row>
                <Col md={9}>Contrato de Crédito Prospera</Col>
                <Col md={3}>
                  <a
                    className="Descarga"
                    style={{ textDecoration: "none", color: "#767676" }}
                    rel="noopener noreferrer"
                    download="Contrato_de_crédito"
                    onClick={() => createLink(process.env.REACT_APP_ARCHIVO_DE_PROSPERA ?? "", "_blank")} 
                  >
                    {" "}
                    <img alt='' src={DownloadIcon} /> Descargar{" "}
                  </a>
                </Col>
              </Row>
            </Card>
            <br />
            <Card body>
              {" "}
              <Row>
                <Col md={9}>Beneficios, riesgos y condiciones - Operaciones activas</Col>
                <Col md={3}>
                  <a
                    className="Descarga"
                    style={{ textDecoration: "none", color: "#767676" }}
                    rel="noopener noreferrer"
                    download="Contrato_de_crédito"
                    onClick={() => createLink(process.env.REACT_APP_BENEFICIOS_RIESGOS_CONDICIONES ?? "", "_blank")} 
                  >
                    {" "}
                    <img alt='' src={DownloadIcon} /> Descargar{" "}
                  </a>
                </Col>
              </Row>
            </Card>


          </Col>
        </Row>
      )
    },
    {
      title: 'Simulador y Fórmulas',
      eventKey: 'simulador',
      body: (
        <Row style={{ padding: 40 }}>
          <Col md={3}>
            <p className="Columna">Documentos descargables</p>
          </Col>
          <Col md={9}>
            <Card body>
              {" "}
              <Row>
                <Col md={9}>Simulador de crédito</Col>
                <Col md={3}>
                  <a
                    className="Descarga"
                    style={{ textDecoration: "none", color: "#767676" }}
                    rel="noopener noreferrer"
                    download="Tarifario_Tasas_Activas"
                    onClick={() => createLink(process.env.REACT_APP_ARCHIVO_DE_SIMULADOR_PROSPERA ?? "", "_blank")} 
                  >
                    {" "}
                    <img alt='' src={DownloadIcon} /> Descargar{" "}
                  </a>
                </Col>
              </Row>
            </Card>
            <br />
            <Card body>
              {" "}
              <Row>
                <Col md={9}>Fórmulas y ejemplos explicativos</Col>
                <Col md={3}>
                  <a
                    className="Descarga"
                    style={{ textDecoration: "none", color: "#767676" }}
                    rel="noopener noreferrer"
                    download="Tarifario_comisión_y_seguro"
                    onClick={() => createLink(process.env.REACT_APP_ARCHIVO_DE_SIMULADOR_INFORMATIVO_PROSPERA ?? "", "_blank")} 
                  >
                    <img alt='' src={DownloadIcon} /> Descargar
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )
    },
    {
      title: 'Preguntas Frecuentes',
      eventKey: 'preguntasfrecuentes',
      body: (
        <SurgirAccordion sections={frequentQuestions}/>
      )
    },
    {
      title: 'Soluciones Financieras',
      eventKey: 'soluciones',
      body: (
        <>
          <Row style={{ padding: 20 }}>
            <Col md={3}>
              <p className="Columna">Refinanciamiento</p>
            </Col>
            <Col md={9}>
              <Card body>
                {" "}
                <Row>
                  <Col md={9}>Refinanciamiento de Créditos</Col>
                  <Col md={3}>
                    <a
                      className="Descarga"
                      style={{ textDecoration: "none", color: "#767676" }}
                      rel="noopener noreferrer"
                      download="Contrato_de_crédito"
                      onClick={() => createLink(process.env.REACT_APP_INFORMATIVO_REFINANCIAMIENTO_DE_CREDITO ?? "", "_blank")} 
                    >
                      {" "}
                      <img alt='' src={DownloadIcon} /> Descargar{" "}
                    </a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row style={{ padding: 20 }}>
            <Col md={3}>
              <p className="Columna">Impulso MYPERÚ</p>
            </Col>
            <Col md={9}>
              <Card body>
                {" "}
                <Row>
                  <Col md={9}>Impulso MYPERÚ</Col>
                  <Col md={3}>
                    <a
                      className="Descarga"
                      style={{ textDecoration: "none", color: "#767676" }}
                      rel="noopener noreferrer"
                      download="Impulso_MYPERÚ"
                      onClick={() => createLink(process.env.REACT_APP_INFORMATIVO_IMPULSO_MYPERU ?? "", "_blank")} 
                    >
                      {" "}
                      <img alt='' src={DownloadIcon} /> Descargar{" "}
                    </a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>

      )
    },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
		const position = window.scrollY;
		setScrollPosition(position);
	};

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const hash = window.location.hash;
    if (hash === "#construyendo-sue%C3%B1os" && construyendoRef.current) {

      setTimeout(() => {
        const dreams = document.getElementById('construyendoDreams')?.offsetTop ?? 0;
        const topBanner = document.getElementById('topBanner')?.scrollHeight ?? 0;

        window.scrollTo({top: (dreams - (56 + (scrollPosition === 0 ? topBanner : 0)))})
      }, 200);

    }
  }, []);
  const mobileTab = useMediaQuery('(max-width: 767px)');
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => setIsModalOpen(false);

  const [hiddenSection] = useState(true)

  return (
    <div>
      <div style={{ paddingTop: 55 }}>
        <div className="banner-prospera" title="Crédito Prospera SURGIR Banner Producto">
          <Container>
            <span className="title-banner" >Crédito Prospera<br />

              <span className="subTextBannerP">Tan rápido como tus ganas de SURGIR  </span>

              <SurgirButton
                onClick={() => setIsModalOpen(true)}
                radius={'32px'}
                fontFamily={"Str-Microtext-Bold"}
                width='160px'
                height='40px'
                marginTop="32px"
                variant="danger"
              >
                Simula tu crédito
              </SurgirButton>


            </span>
          </Container>
        </div>
      </div>
      <Container>
        <div className="segment-containerp">
          <div className="title-segment-content-solidario">
            <span className="title-general-segment" style={{ marginTop: -40 }}>Obtén tu Crédito Prospera, tan rápido como tus ganas de SURGIR</span>
          </div>
          <div className="body-segment-content-solidario-p">
            <Row >
              <Col md={{ span: 4 }}>
                <ItemCardCP
                  id={'first'}
                  text={`Llevamos soluciones financieras hasta tu negocio para que no pierdas tiempo en desplazarte.
                `}
                  position={""}
                  title={"Cuidamos tu tiempo"}
                  image={<img alt='' src={Clock} />}

                />
              </Col>
              <Col md={{ span: 4 }}>
                <ItemCardCP
                  text={`En tu cuenta de ahorros sin costo y sin salir de tu casa o negocio.`}
                  position={""}
                  width={98}
                  title={"Desembolsos rápidos"}
                  image={<img alt='' src={Porki} />}

                />
              </Col>
              <Col md={{ span: 4 }}>
                <ItemCardCP
                  text={`Evaluación sencilla y rápida.
                  Sin trámites engorrosos ni papeleos.
                  `}
                  position={''}
                  width={98}
                  title={'Crédito de fácil acceso'}
                  image={<img alt='' src={HandMoney} />}

                />
              </Col>
            </Row>
          </div>
        </div>
      </Container >
      <div className="boxi">
        <Container >
          <span className="title-general-segment" style={{ marginTop: 10, paddingTop: 50 }}>Tu Crédito Prospera te brinda préstamos de Capital de Trabajo y Activo Fijo para acelerar el crecimiento de tu negocio</span>
          <Row style={{ padding: "1% 2%", margin: 'auto', paddingTop: 45 }} >

            <Col sm={6}>
              <Card style={{ width: '99%', height: '99%', marginTop: 5, marginRight: 5 }} >
                <Card.Img variant="top" src={Chico} />
                <Card.Body className="working-capital-card">
                  <Card.Title style={{ fontFamily: "Str-Microtext-Bold", fontSize: 20, color: "#1D252D", height: '8%' }}>Capital de Trabajo</Card.Title>
                  <Card.Text className="parrafo">
                    El Crédito Prospera te da la oportunidad de obtener capital  para incrementar las ventas de tu negocio.

                  </Card.Text>
                  <Tabs
                    style={{ marginTop: 8 }}
                    defaultActiveKey="beneficios"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="beneficios" title="Beneficios">

                      <ItemTab2 key={0} list={[
                        'Capital para reponer tu stock de campaña rápido y sin preocupaciones.  ',
                        'Capital para ampliar el negocio y comprar mercadería.',
                        ' Amplia red para pagar tus cuotas en KasNet, BCP y Banbif a nivel nacional.',
                        'Podrás solicitar incrementos en tu préstamo, rápido y sin mucho trámite.',

                      ]}
                        last={true}
                      />


                    </Tab>
                  </Tabs>
                </Card.Body>
                <Card.Footer style={{ height: 80, background: '#ffffff', borderTopWidth: 0 }}>
                  <Button style={{ borderRadius: 32, fontFamily: "Str-Microtext-Bold", fontSize: '16', width: 150, height: 40, textAlign: "center" }} variant="danger" href="/contactanos" > Pide tu crédito </Button> {' '}

                </Card.Footer>
              </Card>
            </Col>

            <Col sm={6}>
              <Card style={{ width: '99%', height: '99%', marginTop: 5 }}>
                <Card.Img variant="top" src={Construyendo} />
                <Card.Body>
                  <Card.Title style={{ fontFamily: "Str-Microtext-Bold", fontSize: 20, color: "#1D252D", height: '8%' }}>Activo Fijo</Card.Title>
                  <Card.Text className="parrafo" style={{ marginBottom: 15 }}>
                    Con este crédito también podrás solicitar un préstamo para equipar tu negocio ¡Te financiamos el 100% del activo fijo!
                  </Card.Text>
                  <Tabs
                    style={{ marginTop: -2 }}
                    defaultActiveKey="beneficios"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="beneficios" title="Beneficios">

                      <ItemTab2 key={0} list={[
                        'Te damos la libertad de elegir a tu proveedor.',
                        'No solicitamos garantía por la compra de tu activo fijo.                      ',
                        ' Financiamos la compra de tu maquinaria.                        ',
                        'Te ayudamos a modernizar tu negocio con nuevos equipos inmobiliarios.                       ',
                        'Podrás elegir el plazo que más te conviene para recuperar tu inversión.  ',
                      ]}
                        last={true}
                      />

                    </Tab>
                  </Tabs>
                </Card.Body>
                <Card.Footer style={{ height: 80, background: '#ffffff', borderTopWidth: 0 }}>
                  <Button style={{ borderRadius: 32, fontFamily: "Str-Microtext-Bold", fontSize: '16', width: 150, height: 40, textAlign: "center" }} variant="danger" href="/contactanos" > Pide tu crédito </Button> {' '}

                </Card.Footer>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
      <Container>
        <div>
          <Row style={{ padding: "5% 7%" }} >
            <span className="title-general-segment" >
              Obtén tu préstamo en 3 simples pasos
            </span>
            <Col sm={6} style={{ paddingTop: 15, paddingRight: 30 }}>


              <iframe className="iframe"
                width="95%"
                height="248"
                src="https://www.youtube.com/embed/n4i8qwbZmOo"
                rel="noopener noreferrer"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>


            </Col>
            <Col sm={6} className="col-easy-steps">
              <Row>
                <Col sm={2}>
                  <img alt='' src={Uno} />
                </Col>
                <Col sm={10}>
                  <span
                    style={{
                      fontFamily: "Str-Microtext-Bold",
                      fontSize: 16,
                      color: "#1d252d",
                    }}
                  >
                    Contáctanos
                  </span>
                  <br />
                  <span className="parrafo">
                    Llámanos al (01) 480 0123 o acércate a nuestras oficinas de atención al público para poder atenderte.

                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: 24 }}>
                <Col sm={2}>
                  <img alt='' src={Dos} />
                </Col>
                <Col sm={10}>
                  <span
                    style={{
                      fontFamily: "Str-Microtext-Bold",
                      fontSize: 16,
                      color: "#1d252d",
                    }}
                  >
                    Evaluación y aprobación
                  </span>
                  <br />
                  <span className="parrafo">
                    Programamos tu reunión para llenar la propuesta, verificar tu documentación y darte respuesta sobre la aprobación de tu crédito.
                    {" "}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: 24 }}>
                <Col sm={2}>
                  <img alt='' src={Tres} />
                </Col>
                <Col sm={10}>
                  <span
                    style={{
                      fontFamily: "Str-Microtext-Bold",
                      fontSize: 16,
                      color: "#1d252d",
                    }}
                  >
                    {" "}
                    Desembolsos rápidos y prácticos
                  </span>
                  <br />
                  <span className="parrafo">
                    En tu cuenta de ahorros sin costo y sin salir de tu casa o negocio. Además, paga tu cuota en los <a
                      className="linkturquesa"
                      style={{ color: "#257FA4" }} target="_blank"
                      href="/canales#canales_digitales"
                    >
                      canales digitales
                    </a>. {" "}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="last-segment-container">
          <div className="title-segment-content-solidario">
            <span className="title-general-segment">Entérate más acerca de tu crédito</span>
          </div>
          <div className="body-segment-content-solidario">
            {
              !mobileTab ? 
              <SurgirTabs tabs={TABS_CREDIT_PROSPEROUS} />

            : <div className='container-acordion-responsive'>
              <SurgirAccordion 
                sections={TABS_CREDIT_PROSPEROUS} 
              />
            </div>
            }
            
          </div>
        </div>

        <div style={{ paddingTop: 45 }}>
          <img className="image1"
            style={{
              borderRadius: "14px",

              height: "100%",
              width: "100%"
            }}
            alt="Construyendo Sueños SURGIR Banner Producto"
            src={ConstruyendoS}
          />
          <img
            className="image2"
            style={{
              borderRadius: "14px",
              width: "100%",
              height: "100%",
            }}
            alt="Construyendo Sueños SURGIR Banner Producto"
            src={ConstruyendoSMobile}
          />
          <img
            className="image3"
            style={{
              borderRadius: "14px",
              height: "100%",
              width: "100%"
            }}
            alt="Construyendo Sueños SURGIR Banner Producto"
            src={ConstruyendoSTablet}
          />
        </div>
            
        <div className="title-segment-content-solidario"  ref={construyendoRef} id="construyendo-sueños">
          <span id="construyendoDreams" className="title-general-segment" style={{ marginTop: 10 }}>Crédito Construyendo Sueños</span>
          <p style={{ textAlign: 'center', fontSize: '24px', color: '#9E3667', fontFamily: "Str-Microtext-Regular" }} >
            ¡Lo sueñas rápido, lo cumplimos rápido!
          </p>
        </div>
        <Row  className="building-dreams-content">
          <Col md={2}>

          </Col>
          <Col md={8}>
            <Card body style={{ padding: "16px 0px 16px 35px", borderRadius: 8 }}> <Row><Col md={9}   >
              <span className="parrafo">  Conoce nuestro Crédito Construyendo Sueños</span>
            </Col>
              <Col md={3}>

                <a className="linkrojo" style={{ textDecoration: 'none', fontFamily: "Str-Microtext-Regular", color: '#EC0000' }} onClick={() => createLink(process.env.REACT_APP_ARCHIVO_DE_CONSTRUYENDO_INFORMATIVO ?? "", "_blank")} download="SUPERSEGURO"  >
                  <img alt="Descargar" src={DownloadIcon} />  Descargar </a>
              </Col>
            </Row></Card>
            <br />
            <Card body style={{ padding: "16px 0px 16px 35px", borderRadius: 8 }}> <Row>
              <Col md={9}>
                <span className="parrafo"> Tarifario de Tasas Activas </span>
              </Col>
              <Col md={3}>
                <a className="linkrojo" style={{ textDecoration: 'none', fontFamily: "Str-Microtext-Regular", color: '#EC0000' }} onClick={() => createLink(process.env.REACT_APP_TARIFARIOS_ACTIVAS ?? "", "_blank")} download="Certificado de Superseguro">
                  <img alt='' src={DownloadIcon} />   Descargar
                </a>
              </Col>
            </Row></Card>
            <br />
            <Card body style={{ padding: "16px 0px 16px 35px", borderRadius: 8 }}> <Row>
              <Col md={9}>
                <span className="parrafo"> Tarifario de Comisiones y Seguros </span>
              </Col>
              <Col md={3}>
                <a className="linkrojo" style={{ textDecoration: 'none', fontFamily: "Str-Microtext-Regular", color: '#EC0000' }} onClick={() => createLink(process.env.REACT_APP_TARIFARIO_COMISION_Y_SEGURO ?? "", "_blank")} download="Certificado de Superseguro">
                  <img alt='' src={DownloadIcon} />   Descargar
                </a>
              </Col>
            </Row></Card>
            <br />
            <Card body style={{ padding: "16px 0px 16px 35px", borderRadius: 8 }}> <Row>
              <Col md={9}>
                <span className="parrafo"> Contrato de Crédito Construyendo Sueños </span>
              </Col>
              <Col md={3}>
                <a className="linkrojo" style={{ textDecoration: 'none', fontFamily: "Str-Microtext-Regular", color: '#EC0000' }} onClick={() => createLink(process.env.REACT_APP_ARCHIVO_DE_CONSTRUYENDO ?? "", "_blank")} download="Certificado de Superseguro">
                  <img alt='' src={DownloadIcon} />   Descargar
                </a>
              </Col>
            </Row></Card>
            <br />
            <Card body style={{ padding: "16px 0px 16px 35px", borderRadius: 8 }}> <Row>
              <Col md={9}>Beneficios, riesgos y condiciones - Operaciones activas</Col>
              <Col md={3}>
                <a
                  className="linkrojo" style={{ textDecoration: 'none', fontFamily: "Str-Microtext-Regular", color: '#EC0000' }}
                  rel="noopener noreferrer"
                  download="Contrato_de_crédito"
                  onClick={() => createLink(process.env.REACT_APP_BENEFICIOS_RIESGOS_CONDICIONES ?? "", "_blank")} 
                >
                  {" "}
                  <img alt='' src={DownloadIcon} /> Descargar{" "}
                </a>
              </Col>
            </Row></Card>
            <br />
            <Card body style={{ padding: "16px 0px 16px 35px", borderRadius: 8 }}>
              <Row>
                <Col md={9}>Refinanciamiento de Créditos</Col>
                <Col md={3}>
                  <a
                    className="linkrojo" style={{ textDecoration: 'none', fontFamily: "Str-Microtext-Regular", color: '#EC0000' }}
                    rel="noopener noreferrer"
                    download="Contrato_de_crédito"
                    onClick={() => createLink(process.env.REACT_APP_INFORMATIVO_REFINANCIAMIENTO_DE_CREDITO ?? "", "_blank")} 
                  >
                    {" "}
                    <img alt='' src={DownloadIcon} /> Descargar{" "}
                  </a>
                </Col>
              </Row>
            </Card>

            <br />
            <Card body style={{ padding: "16px 0px 16px 35px", borderRadius: 8 }}>
              <Row>
                <Col md={9}>Impulso MYPERÚ</Col>
                <Col md={3}>
                  <a
                    className="linkrojo" style={{ textDecoration: 'none', fontFamily: "Str-Microtext-Regular", color: '#EC0000' }}
                    rel="noopener noreferrer"
                    download="Impulso_MYPERÚ"
                    onClick={() => createLink(process.env.REACT_APP_INFORMATIVO_IMPULSO_MYPERU ?? "", "_blank")} 
                  >
                    {" "}
                    <img alt='' src={DownloadIcon} /> Descargar{" "}
                  </a>
                </Col>
              </Row>
            </Card>
            <br />
            <Card body style={{ padding: "16px 0px 16px 35px", borderRadius: 8 }}> <Row>
              <Col md={9}>Preguntas Frecuentes</Col>
              <Col md={3}>
                <a
                  className="linkrojo" style={{ textDecoration: 'none', fontFamily: "Str-Microtext-Regular", color: '#EC0000' }}
                  rel="noopener noreferrer"
                  download="Contrato_de_crédito"
                  onClick={() => createLink(process.env.REACT_APP_PROD_CONST_PREGUNTAS_FRECUENTES ?? "", "_blank")} 
                >
                  {" "}
                  <img alt='' src={DownloadIcon} /> Descargar{" "}
                </a>
              </Col>
            </Row></Card>

          </Col>
          <Col md={2}>

          </Col>
        </Row>
        <br />
      </Container>

      {
        !hiddenSection && <div className="box">
        <Container >
          <h1 style={{ textAlign: "center", fontSize: 32, color: "#ffffff", marginBottom: 0 }}>
            <br /><br />

            También te puede interesar
          </h1>
          <Row className="row-also-be-interested">
            <Col sm={1}>
            </Col>
            <Col className="interest-col" sm={3} >
              <Card
                style={{
                  width: "99%",
                  height: "98%",
                  margin: "5px 50px 5px 0px ",
                }}
              >
                <Card.Img variant="top" src={Mujeres} />
                <Card.Body>
                  <Card.Title
                    style={{
                      fontFamily: "Str-Microtext-Bold",
                      fontSize: 20,
                      color: "#7c1844",
                    }}
                  >
                    Crédito Mujeres Unidas
                  </Card.Title>
                  <Card.Text className="parrafo">
                    Para no dejar desprotegida a tu familia en caso de muerte o
                    accidente temporal.
                    <br />
                    <br />
                  </Card.Text>
                  <a
                    className="linkrojo"
                    style={{
                      color: "#ec0000",
                      fontFamily: "Str-Microtext-Regular",
                      fontSize: 16,
                    }}
                    href="/prestamo-solidario"
                    rel="noopener noreferrer"
                  >
                    Leer más{" "}
                  </a>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={3} className="interest-col learn-more">
              <Card style={{ width: "99%", height: "98%", marginTop: 5 }}>
                <Card.Img variant="top" src={Chicas} />
                <Card.Body>
                  <Card.Title
                    style={{
                      fontFamily: "Str-Microtext-Bold",
                      fontSize: 20,
                      color: "#7c1844",
                    }}
                  >
                    Conoce más sobre nosotros
                  </Card.Title>
                  <Card.Text className="parrafo">
                    Somos SURGIR y formamos parte del Grupo Santander, llevamos
                    más de 160 años de experiencia en el mercado financiero.
                  </Card.Text>
                  <a
                    className="linkrojo"
                    style={{
                      color: "#ec0000",
                      fontFamily: "Str-Microtext-Regular",
                      fontSize: 16,
                    }}
                    href="/quienes-somos"
                    rel="noopener noreferrer"
                  >
                    Leer más{" "}
                  </a>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col sm={3} style={{ marginLeft: 1 }}>
              <Card style={{ width: "99%", height: "98%", marginTop: 5, marginLeft: 10 }}>
                <Card.Img variant="top" src={Financiera} />
                <Card.Body>
                  <Card.Title style={{ fontFamily: "Str-Microtext-Bold", fontSize: 20, color: "#7c1844" }}>Seguro de Desgravamen Porcentual</Card.Title>
                  <Card.Text className="parrafo">
                    Protege a tu familia al librarla de posibles deudas pendientes en caso de fallecimiento, o invalidez total y permanente.<br />
                  </Card.Text>
                  <a
                    className="linkrojo"
                    style={{
                      color: "#ec0000",
                      fontFamily: "Str-Microtext-Regular",
                      fontSize: 16,
                      marginLeft: "70%",
                    }}
                    href="/seguro-desgravamen"
                    rel="noopener noreferrer"
                  >
                    Leer más{" "}
                  </a>

                </Card.Body>
              </Card>

            </Col> */}
            <Col sm={1}>
            </Col>
          </Row>
        </Container>


      </div>
      }

      <SurgirModalDownload
        title={'Simulador de crédito'}
        description="Selecciona el documento que deseas abrir y descargar."
        show={isModalOpen}
        handleClose={handleClose}
        documentList={[
          { Icon: XLS, document: process.env.REACT_APP_ARCHIVO_DE_SIMULADOR_PROSPERA ?? '', downloadName: "Simulador_credito", name: 'Simulador de crédito (Descargar)' },
          { Icon: PDF, document: process.env.REACT_APP_ARCHIVO_DE_SIMULADOR_INFORMATIVO_PROSPERA ?? '', downloadName: "Formula_ejemplos", name: 'Fórmulas y ejemplos explicativos (Descargar)' },
        ]}
      ></SurgirModalDownload>

    </div>
  );
}

export default CreditProsperous